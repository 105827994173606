import React from 'react';
import Layout from '../components/common/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Layout>
    <SEO />
    <div>
      <h1>404 page</h1>
    </div>
  </Layout>
);

export default NotFoundPage;
